<template>
  <div class="error-content">
    <div class="card mb-0">
      <div class="card-body text-center">
        <h1
          class="error-text permission-denied-text text-primary"
        >Permission Denied!</h1>
        <p>Sorry,you are not allowed to view this page.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>